import React, { useEffect, useState } from 'react';
import differenceInYears from 'date-fns/differenceInYears';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './index.css';

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const randomize = (arr) => {
  const randomizedArray = [];
  const freePositions = arr.map((arr, index) => index);
  
  arr.forEach((element) => {
    const randomIndex = freePositions.splice(getRandomInt(0, freePositions.length), 1)[0];
    randomizedArray.push(arr[randomIndex]);
  });

  return randomizedArray;
};

const getAge = () => {
  const bday = new Date('1989-01-02');
  const now = new Date();
  return differenceInYears(now, bday);
};

const getTechnologiesRandomized = () => {
  const technologies = [
    'amazonwebservices', 
    'babel', 
    'css3', 
    'docker', 
    'express', 
    'git', 
    'github', 
    'html5', 
    'javascript', 
    'linux', 
    'mongodb', 
    'mysql', 
    'nginx',
    'nodejs',
    'react',
    'sass',
    'typescript',
    'ubuntu',
    'wordpress'
  ];

  return randomize(technologies);
};

const IndexPage = () => {
  const [age, setAge] = useState(0);
  const [techs, setTechs] = useState([]);

  useEffect(() => {
    setAge(getAge());
    setTechs(getTechnologiesRandomized());
  }, []);

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Dominik Voss</h1>
      <ul>
        <li>What: Full Stack Developer</li>
        <li>Where: Munich, Germany</li>
        <li>Age: {age}</li>
      </ul>
      <p>Technologies:</p>
      <div className="tech-grid">
        {techs.map((tech) => (
          <div style={{
            backgroundImage: `url(${require(`../images/devicons/${tech}.svg`)})`,
          }}
            key={tech}
            title={tech}
          ></div>
        ))}
      </div>
    </Layout>
  );
};

export default IndexPage;
